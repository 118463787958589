<template>
  <div class="text-template-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :enable-refresh="true" :handle-refresh="refreshData" :title="$t('Venues')" />

          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <TopicFilterForm :values="filters" ref="filterForm" slot="filter" />
          </DataFilter>

          <br />
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template #item.rate="{ item }"> {{ item.rate }} € </template>
            <template #item.flatRate="{ item }">
              {{ item.flatRate }} €
            </template>
            <template #item.enabled="{ item }">
              <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-close-circle</v-icon>
            </template>
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import TopicFilterForm from "../../components/topic/Filter";

export default {
  name: "VenueList",
  servicePrefix: "Venue",
  mixins: [ListMixin],
  components: {
    Toolbar,
    ActionCell,
    DataFilter,
    TopicFilterForm,
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t("name"), value: "name" },
        { text: this.$i18n.t("contactPerson"), value: "contactPerson" },
        { text: this.$i18n.t("hotelRate"), value: "rate" },
        { text: this.$i18n.t("flatRate"), value: "flatRate" },
        { text: this.$i18n.t("enabled"), value: "enabled" },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
        },
      ],
      selected: [],
      filters: {
        __Fexists: { parent: false },
      },
    };
  },
  computed: {
    ...mapGetters("venue", {
      items: "list",
    }),
    ...mapFields("venue", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    }),
  },
  methods: {
    ...mapActions("venue", {
      getPage: "fetchAll",
      deleteItem: "del",
      clearVenueData: 'resetAllData',
    }),
    resetFilter() {
      this.filters = {
        profile: {},
      };
    },
    refreshData(){
      this.clearVenueData();
      this.onSendFilter();
    }
  },
};
</script>
